// src/pages/Blog.jsx
import React from "react";
import { Box, Typography } from "@mui/material";

const Blog = () => {
  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Blog
      </Typography>
      <Typography variant="body1">
        Welcome to the Blog section. Here you can find interesting articles.
      </Typography>
    </Box>
  );
};

export default Blog;
