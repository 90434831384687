import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const welcomeVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const cardVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: (index) => ({
      opacity: 1,
      x: 0,
      transition: { delay: index * 0.2, duration: 0.8 },
    }),
  };
  const navItems = [
    { label: "Application", path: "/application" },
    { label: "Blog", path: "/blog" },
    { label: "My Portfolio", path: "/portfolio" },
  ];

  return (
    <Box
      component={motion.div}
      initial="hidden"
      animate="visible"
      sx={{
        background: theme.palette.primary.background,
        color: theme.palette.text.primary,
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: "50px",
        boxSizing: "border-box",
      }}
    >
      {/* Welcome Section */}
      <motion.div variants={welcomeVariants}>
        <Typography variant="h3" gutterBottom>
          Welcome to Trees Stack
        </Typography>
        <Typography variant="h6" gutterBottom>
          Discover amazing tools, resources, and projects.
        </Typography>
      </motion.div>
      {/* Cards Section */}
      <Grid
        container
        spacing={4}
        sx={{ marginTop: "30px", maxWidth: "1200px" }}
        component={motion.div}
        initial="hidden"
        animate="visible"
      >
        {navItems.map((item, index) => (
          <Grid
            item
            xs={12}
            md={4}
            key={index}
            component={motion.div}
            custom={index}
            variants={cardVariants}
          >
            <Card
              sx={{
                display: "flex",
                flexDirection: "row",
                background: theme.palette.secondary.main,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                color: theme.palette.text.primary,
                height: "150px",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <CardContent sx={{ flex: 1 }}>
                <Typography variant="h6">{item.label}</Typography>
                <Typography variant="body2" mt={1}>
                  Explore more about {item.label}.
                </Typography>
                <Box
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.accent.dark,
                      "&:hover": {
                        backgroundColor: theme.palette.accent.light,
                      },
                    }}
                    onClick={() => navigate(item.path)}
                  >
                    Learn More
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      ;
    </Box>
  );
};

export default LandingPage;
