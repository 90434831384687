// src/pages/Portfolio.jsx
import React from "react";
import { Box, Typography, Avatar, Grid } from "@mui/material";
const skills = [
  "React Native",
  "Blockchain",
  "Android Development",
  "ReactJS",
  "NFC",
  "Bluetooth",
];

const Portfolio = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "background.default",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px", // Centered content with width restriction
          backgroundColor: "accent.light",
          borderRadius: "10px",
          padding: "40px 20px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Model-like shadow
        }}
      >
        {/* Header with Image */}
        <Box sx={{ textAlign: "center", marginBottom: "40px" }}>
          <Avatar
            alt="Santhosh R"
            src="https://via.placeholder.com/150"
            sx={{
              width: 120,
              height: 120,
              margin: "0 auto",
              border: "3px solid white",
            }}
          />
          <Typography variant="h3" gutterBottom>
            Santhosh R
          </Typography>
          <Typography variant="body1">
            Senior Software Engineer specializing in Mobile App Development.
          </Typography>
        </Box>

        {/* Summary Section */}
        <Box
          sx={{
            marginBottom: "30px",
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: "secondary.main",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Summary
          </Typography>
          <Typography variant="body1">
            I am a Senior Software Engineer with over 7 years of experience in
            mobile and full-stack development. Skilled in Android, React Native,
            and ReactJS, with expertise in Agile methodologies and team
            leadership.
          </Typography>
        </Box>

        {/* Key Skills Section */}
        <Box sx={{ marginBottom: "30px" }}>
          <Typography variant="h6" gutterBottom>
            Key Skills
          </Typography>
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            {skills.map((skill, index) => (
              <Grid item key={index}>
                <Box
                  sx={{
                    padding: "10px 20px",
                    borderRadius: "50px", // Rounded corners
                    backgroundColor: "secondary.main",
                    textAlign: "center",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Typography variant="body1">{skill}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Projects Section */}
        <Box
          sx={{
            marginBottom: "30px",
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: "secondary.main",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Projects
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>Trag Producer App:</strong> Blockchain-based product
                province updates.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Masquerader Xperience:</strong> Carnival costume
                scheduling and booking.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Klefki:</strong> Decentralized ID and credentials using
                blockchain.
              </Typography>
            </li>
          </ul>
        </Box>

        {/* Other Section */}
        <Box
          sx={{
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: "secondary.main",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Other Experiences
          </Typography>
          <Typography variant="body1">
            Worked on Bugasura (web and Android app), developed hybrid apps
            using React Native, and automated testing using Appium.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Portfolio;
