// src/styles/theme.js
import { createTheme } from "@mui/material/styles";

// --text: #050315;
// --background: #ffffff;
// --primary: #315098;
// --secondary: #8ca8be;
// --accent: #afbdb0;

const theme = createTheme({
  palette: {
    primary: {
      main: "#315098", // Primary background color
      background: "#ffffff",
    },
    secondary: {
      main: "#efefef", // Secondary color
      background: "#ffffff",
    },
    text: {
      primary: "#000000", // Black text color
      secondary: "#34495e", // Secondary text
    },
    background: {
      default: "#ffffff", // Background color
    },
    accent: {
      light: "#8ca8be",
      dark: "#065758",
      warning: "#ffef30",
    },
  },
  typography: {
    fontFamily: `'Roboto', 'Arial', sans-serif`,
    h3: {
      fontWeight: 600,
      color: "#000000", // Default header text color
    },
    h6: {
      fontWeight: 900,
      fontSize: 30,
      color: "#000000", // Secondary header text color
    },
    body1: {
      fontSize: "1rem",
      color: "#000000", // Default body text color
    },
    button: {
      fontWeight: 900,
      textTransform: "none",
    },
  },
});

export default theme;
