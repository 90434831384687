// src/components/Footer.jsx
import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: "#2c3e50",
        color: "white",
        padding: "20px",
        textAlign: "center",
        marginTop: "auto", // Ensures footer is pushed to the bottom
      }}
    >
      <Typography variant="body1">&copy; 2024 Trees Stack</Typography>
      <Typography variant="body2">All rights reserved</Typography>
    </Box>
  );
};

export default Footer;
