import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  useTheme,
} from "@mui/material";

const mobileApps = [
  {
    appName: "Data Stacks",
    version: "1.0",
    imageUrl: "ds_logo.png",
    description:
      "Data Stacks is your gateway to diverse datasets, powered by data.gov.in's open-source API.",
    playStoreLink:
      "https://play.google.com/store/apps/details?id=com.treestacks.datastacks",
  },
  {
    appName: "Companys Stacks",
    version: "1.0",
    imageUrl: "cs_logo.png",
    description:
      "Easily access detailed information about companies registered with the Registrar of Companies (RoC) in India.",
    playStoreLink:
      "https://play.google.com/store/apps/details?id=com.treestacks.companymasterstacks",
  },
];

const Application = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(4),
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: theme.palette.text.primary,
          marginBottom: theme.spacing(2),
        }}
      >
        Applications
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.text.secondary,
          marginBottom: theme.spacing(4),
        }}
      >
        Explore the latest applications built with Trees Stack.
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {mobileApps.map((app, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card
              sx={{
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[2],
                maxWidth: 400,
                margin: "auto",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardMedia
                component="img"
                alt={app.appName}
                height="140"
                image={app.imageUrl}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.text.primary, fontWeight: 600 }}
                  gutterBottom
                >
                  {app.appName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary }}
                  gutterBottom
                >
                  Version: {app.version}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.primary,
                    marginBottom: theme.spacing(2),
                  }}
                >
                  {app.description}
                </Typography>
                <a
                  href={app.playStoreLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.primary.main,
                    fontWeight: theme.typography.fontWeightBold,
                  }}
                >
                  View on Play Store
                </a>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Application;
